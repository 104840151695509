<template>
  <div>
    <div class="container">
      <div class="pp-text">恭喜你匹配成功</div>
      <!-- 匹配卡片 -->
      <div class="cp-card">
        <div class="cp-box1">
          <div class="img-box">
            <img class="cp-img" :src="cpInfo.imgUrl" />
          </div>
        </div>
        <div class="cp-box2">
          <div class="cp- school">
            {{ cpInfo.school }}·{{ cpInfo.grade }}·{{ cpInfo.height }}
          </div>
        </div>  
        <div class="cp-more">{{ cpInfo.more }}</div>
      </div>
      <div class="cp-btn-box1" v-if="isAllpay == false">
        <div v-if="isPay == false">
          <div v-if="isPaying == false" class="cp-btn-xd4" @click="onPay()">支付</div>
        <div v-else class="cp-btn-xd4">正在支付中</div>
        </div>
        
        <div style="height:5px"></div>
        <div class="cp-ps-text">*在对方支付报名费报名之前，你可以随时点击“不再心动”退款</div>
        <div class="cp-btn-xd3" @click="refunds()">不再心动</div>
      </div>
      <div style="height:40px"></div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "Inquire",
  props: ["openid","userRes","cpInfo","loveInfo"],
  data() {
    return {
      inLove: false,
      oneList: [],
      isPaying:false,
      isPay:false,
      isAllpay:false
    };
  },
  methods: {
    //获得签名
    async onQm() {
      const noncestr = Math.random()
        .toString(36)
        .substr(2, 15);
      const timestamp = parseInt(new Date().getTime() / 1000);
      const tcb = this.$cloudbase;
      const ticketRes = await tcb.callFunction({
        // 云函数名称
        name: "getSignature",
        // 传给云函数的参数
        data: {
          url: this.url,
          timestamp,
          noncestr
        }
      });
      console.log(ticketRes.result);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx7fb8dcd86d54fbca", // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: ticketRes.result, // 必填，签名
        jsApiList: [
          "chooseWXPay",
          "chooseImage",
          "getLocalImgData",
          "uploadImage",
          "downloadImage",
          "updateAppMessageShareData"
        ], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-launch-weapp"]
      });
    },
    //支付
    async onPay() {
      const that = this;
      this.isPaying = true;
      const tcb = this.$cloudbase;
      let total = 0;
      console.log("this.userRes.userinfo", this.userRes.userinfo);

      if (this.userRes.userinfo.sex == "girl") {
        total = 990;
      } else {
        total = 1990;
      }
      const mpPayRes = await tcb.callFunction({
        name: "mpPay",
        data: {
          openid: this.openid,
          total
        }
      });
      console.log(mpPayRes.result);
      const payData = mpPayRes.result;
      await wx.chooseWXPay({
        appId: 'wx7fb8dcd86d54fbca',
        timestamp: payData.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
        nonceStr: payData.nonceStr, // 支付签名随机串，不长于 32 位
        package: payData.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
        signType: payData.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
        paySign: payData.paySign, // 支付签名
        success: function(res) {
          console.log(res);
          that.isPay = true;
        },
        cancel: function(err) {
          // 用户取消支付
          console.log(err);
          that.isPaying = false;
        },
        fail: function(res) {
          // 支付失败
          console.log(res);
          that.isPaying = false;
        }
      });
    },
    //退款
    async refunds(){
      const tcb = this.$cloudbase;
      const refundsRes = await tcb.callFunction({
        name: "refunds",
        data: {
          loveInfo: this.loveInfo,
        }
      });
      console.log(refundsRes,'refundsRes')
      
    }
  },
  async created() {
    console.log('loveInfo',this.loveInfo)
    const url = location.href.split("#")[0];
    this.url = url;
    this.onQm();
    
    // const tcb = this.$cloudbase;
    const sex = this.userRes.userinfo.sex
    if(sex=="boy"){
      if(this.loveInfo.boyIspay==true){
        this.isPay = true
      }
    }else{
      if(this.loveInfo.girlIspay==true){
        this.isPay = true
      }
    }
    if(this.loveInfo.boyIspay==true&&this.loveInfo.girlIspay==true){
      this.isAllpay = true
    }
  }
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.bg-img {
  display: flex;
  margin: auto;
  margin-top: 40px;
}

.xh-text {
  font-size: 21px;
  margin-left: 50px;
  font-weight: 700;
  margin-top: 50px;
}

.pp-text {
  font-size: 21px;
  margin: auto;
  font-weight: 700;
  margin-top: 30px;
}

.logo {
  width: 282px;
  height: 282px;
}

.input-input {
  border: 3px solid #747474;
  width: 300px;
  height: 41px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 35px;
  border-radius: 15px;
  text-align: center;
  font-weight: 900;
  color: #747474;
  font-size: 17px;
}

.btn {
  border: 3px solid #747474;
  width: 304px;
  height: 44px;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.card {
  display: flex;
  flex-direction: column;
  width: 335px;
  margin: auto;
  margin-top: 40px;
  background-color: #ffffff;
  box-shadow: #c1c1c144 0 6px 6px 6px;
  border-radius: 20px;
  color: #747474;
  font-size: 21px;
  font-weight: 500;
}

.text {
  margin: auto;
  margin-top: 30px;
}

.img-sum {
  width: 80px;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 55px;
}

.a-sex {
  border: 3px solid #747474;
  width: 280px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
  border-radius: 20px;
  text-align: center;
  line-height: 60px;
}

.a-sex0 {
  border: 3px solid #747474;
  width: 280px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 60px;
}

.chooseBtn {
  background-color: #f7de47;
  color: #747474;
}

.text-box {
  display: flex;
  width: 100%;
}

.cp-box {
  display: flex;
  flex-direction: column;
  margin: auto;
}

.qun {
  width: 282px;
  height: 100%;
  margin: auto;
  margin-top: 100px;
}

.find-more {
  margin: auto;
  width: 300px;
}

.cp-btn-box1{
  display: flex;
  flex-direction: column;
  margin-top: 25px;
}

.cp-btn-xd4 {
  border: 3px solid #747474;
  width: 320px;
  height: 44px;
  margin: 0px 0px 25px 0px;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.cp-btn-xd3 {
  border: 3px solid #747474;
  width: 320px;
  height: 44px;
  margin: 0px 0px 25px 0px;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #ffffff;
  color: #747474;
  font-weight: 900;
}

</style>
