import Vue from "vue";
import Router from "vue-router";

import Apply from "./components/Apply";
import Self from "./components/Self";
import ILike from "./components/ILike";
import LikeMe from "./components/LikeMe";
import Inquire from "./components/Inquire";

Vue.use(Router);

const router = new Router({
    routes: [{
            path: "/Apply",
            name: "Apply",
            component: Apply,
        },
        {
            path: "/Self",
            name: "Self",
            component: Self,
        },
        {
            path: "/ILike",
            name: "ILike",
            component: ILike,
        },
        {
            path: "/LikeMe",
            name: "LikeMe",
            component: LikeMe,
        },
        {
            path: "/Inquire",
            name: "Inquire",
            component: Inquire,
        }
    ],
});

export default router;