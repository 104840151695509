<template>
  <div class="container">
    <!-- 修改 -->
    <div @click="toApply()" class="user-box">
      <div class="user-box1">
        <div class="avatar">
          <img :src="userinfo.imgUrl" alt class="user-box-img" />
        </div>

        <div class="user-box2">
          <div class="user-name">
            {{ userinfo.grade }}·{{ userinfo.height }}
          </div>
          <div class="user-perfect">编辑个人资料</div>
        </div>
        <div class="user-box3">修改</div>
      </div>
    </div>

    <!-- 喜欢卡片 -->
    <div class="btn-card">
      <div class="like-box" @click="toILike">
        <div class="like-text">我心动的人</div>
        <div class="like-right">
          <img class="jt" src="../assets/jt.png" />
        </div>
      </div>
      <div class="line"></div>
      <div class="like-box" @click="toLikeMe">
        <div class="like-text">心动我的人</div>
        <div class="like-right">
          <!-- <div class="like-right-num">{{likeMe}}</div> -->
          <img class="jt" src="../assets/jt.png" />
        </div>
      </div>
    </div>

    <!-- 匹配卡片 -->
    <div v-if="isCpinfo == true" class="cp-card" :class="{ no: no, yes: yes }">
      <div class="cp-box1">
        <div class="img-box">
          <img class="cp-img" :src="cpinfo.imgUrl" />
        </div>
      </div>
      <div class="cp-box2">
        <div class="cp-school">
          {{ cpinfo.school }}·{{ cpinfo.grade }}·{{ cpinfo.height }}
        </div>
      </div>
      <div class="cp-more">{{ cpinfo.more }}</div>
      <div class="cp-btn-box">
        <!-- <div class="cp-btn-xd" @click="firstYes()">心动一下</div> -->
        <div>
          <wx-open-subscribe
            template="eBHdlRgJOGphIerjExqBABwB9GXQOLB5TI5G1pic8RU,czHtiOm545II5qUbp9wa2Z1JkqsjjlkdyChMQCYwrDM"
            id="subscribe-btn"
            @success="successSubscribe"
            @error="subErrorSubscribe"
          >
            <script type="text/wxtag-template">
                          <style>
                          .subscribe-btn{
                             border: 3px solid #747474;
              width: 200px;
              height: 50px;
              margin: 0px 25px 25px 25px;
              border-radius: 15px;
              text-align: center;
              line-height: 46px;
              background-color: #f7de47;
              color: #747474;
              font-weight: 900;
              font-size: 17px;
                          }
                                </style>
                                <button class="subscribe-btn">心动一下</button>
            </script>
          </wx-open-subscribe>
        </div>
        <div class="choose-btn" @click="noGuy()">
          <img class="choose-no" src="../assets/no.png" />
        </div>
      </div>
    </div>
    <div style="height: 40px"></div>
  </div>
</template>
<script>
import wx from "weixin-js-sdk";
export default {
  name: "Self",
  props: ["openid", "userRes"],
  data() {
    return {
      isCpinfo: false,
      cpinfo: {},
      no: false,
      yes: false,
      // template: [
      //   "VWydGoEuN7hKByX8zFl7yPjn2WBPCxTGVnN84sMD6S8",
      //   "eBHdlRgJOGphIerjExqBABwB9GXQOLB5TI5G1pic8RU",
      //   "czHtiOm545II5qUbp9wa2Z1JkqsjjlkdyChMQCYwrDM",
      // ],
    };
  },
  created() {
    const url = location.href.split("#")[0];
    this.url = url;
    //完成签名
    this.onQm();
    this.userinfo = this.userRes.userinfo;
    this.userId = this.userRes._id;
    this.noGuy();
  },
  methods: {
    //获得签名
    async onQm() {
      const noncestr = Math.random().toString(36).substr(2, 15);
      const timestamp = parseInt(new Date().getTime() / 1000);
      const tcb = this.$cloudbase;
      const ticketRes = await tcb.callFunction({
        // 云函数名称
        name: "getSignature",
        // 传给云函数的参数
        data: {
          url: this.url,
          timestamp,
          noncestr,
        },
      });
      console.log(ticketRes.result);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx7fb8dcd86d54fbca", // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: ticketRes.result, // 必填，签名
        jsApiList: [
          "chooseWXPay",
          "chooseImage",
          "getLocalImgData",
          "uploadImage",
          "downloadImage",
        ], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-subscribe"],
      });
    },
    //路由跳转
    toApply() {
      this.$router.push("Apply");
    },
    toLikeMe() {
      this.$router.push("LikeMe");
    },
    toILike() {
      this.$router.push("ILike");
    },

    async noGuy() {
      this.no = true;
      const sex = this.userinfo.sex;
      const tcb = this.$cloudbase;
      const db = tcb.database();
      let findSex = "";
      console.log("sex", sex);

      if (sex == "boy") {
        findSex = "girl";
      } else {
        findSex = "boy";
      }
      console.log("findSex", findSex);

      var cpRes = await db
        .collection("user")
        .aggregate()
        .match({
          userinfo: {
            sex: findSex,
            // mpOpenid: "oaNVb54hEBFvx5Y-kLeZfT-_dY7I",
          },
          inLove: false,
          isShow: true,
        })
        .sample({
          size: 1,
        })
        .end();
      console.log(cpRes, "list");
      this.cpinfo = cpRes.data[0].userinfo;
      this.cpId = cpRes.data[0]._id;
      this.isCpinfo = true;
      console.log(this.isCpinfo);
      this.no = false;
    },
    successSubscribe(e) {
      let subscribeDetails = JSON.parse(e.detail.subscribeDetails);
      let status = JSON.parse(
        subscribeDetails[
          ("eBHdlRgJOGphIerjExqBABwB9GXQOLB5TI5G1pic8RU",
          "czHtiOm545II5qUbp9wa2Z1JkqsjjlkdyChMQCYwrDM")
        ]
      ).status;
      if (status == "accept") {
        //同意订阅该模板
        console.log("success");
        this.firstYes();
      } else if (status == "reject") {
        //拒绝订阅该模板
        console.log("fail");
      }
    },
    subErrorSubscribe(e) {
      let errMsg = e.detail.errMsg; //错误提示
      let errCode = e.detail.errCode; //错误码
      console.log(errMsg, errCode);
    },
    async firstYes() {
      this.yes = true;
      const cpId = this.cpId;
      const userId = this.userId;
      console.log(cpId, 11, userId, 22);
      const tcb = this.$cloudbase;
      const firstYes = await tcb.callFunction({
        name: "firstYes",
        data: {
          cpId,
          userId,
          sex: this.sex,
        },
      });
      console.log(firstYes);
      this.noGuy();
      this.yes = false;
    },
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

/* 用户卡片 */

.user-box {
  margin: auto;
  width: 335px;
}

.user-box1 {
  display: flex;
  flex-direction: row;

  margin-top: 25px;
}

.avatar {
  overflow: hidden;
  border-radius: 50%;
  margin: 20px 20px 20px 0px;
  width: 80px;
  height: 80px;
}

.user-box-img {
  width: 80px;
  height: 100%;
  filter: blur(6px);
}

.user-box2 {
  display: flex;
  flex-direction: column;
  width: 155px;
  color: #747474;
  font-weight: 600;
  margin-top: 25px;
  align-content: space-between;
}

.user-name {
  font-size: 21px;
  width: 120px;
  margin-top: 3px;
}

.user-perfect {
  font-size: 12px;
  width: 120px;
  margin-top: 5px;
}

.user-box3 {
  display: flex;
  flex-direction: row;
  margin: 39px 0px 0px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #9f9f9f;
}

.pay-card {
  display: flex;
  flex-direction: column;
  width: 335px;
  margin: auto;
  margin-top: 10px;
  background-color: #ffffff;
  box-shadow: #c1c1c144 0 6px 6px 6px;
  border-radius: 20px;
  color: #747474;
  font-size: 21px;
  font-weight: 500;
}

.pay-box {
  display: flex;
  flex-direction: column;
  margin: 20px 0px 20px 0px;
}

.pay-text {
  font-size: 21px;
  margin: auto;
  margin-bottom: 20px;
}

.pay-btn {
  font-size: 17px;
  border: 3px solid #747474;
  width: 120px;
  height: 40px;
  margin: auto;

  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.line {
  width: 335px;
  height: 1px;
  background-color: #f5f5f5;
}

/* 查看按钮 */
.jt {
  width: 5px;
  height: 8px;
  margin: 5px 0px 0px 6px;
}

.choose-yes {
  width: 64rpx;
  margin: 28rpx 18rpx;
}

.btn-card {
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 100px;
  margin: 0rpx 20px 0px 20px;
  margin: auto;
  background-color: #ffffff;
  box-shadow: #c1c1c144 0 6px 6px 1px;
  border-radius: 20px;
}

.like-box {
  height: 50px;
  width: 335px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.like-text {
  font-size: 34rpx;
  color: #747474;
  font-weight: 500;
  margin: 15px 0px 0px 20px;
}

.like-right-num {
  font-size: 12px;
  font-weight: 500;
  color: #9f9f9f;
}

.like-right {
  margin: 20px 15px 0px 0px;
  display: flex;
  flex-direction: row;
}

.cp-ps-text {
  font-size: 11px;
  color: #747474;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  margin: auto;
}

.cp-ps-text1 {
  font-size: 11px;
  color: #747474;
  height: 20px;
  width: 300px;
  margin: auto;
  text-align: center;
  margin-top: 10px;
}

.cp-card {
  width: 335px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  box-shadow: #c1c1c144 0 6px 6px 6px;
  border-radius: 20px;
}

.cp-box1 {
  display: flex;
  /* flex-direction: row; */
}

.cp-box2 {
  display: flex;
  flex-direction: column;
  color: #747474;
  font-weight: 600;
  margin: 10px 0px 9px 25px;
}

.img-box {
  width: 335px;
  border-radius: 20px 20px 0 0;
  overflow: hidden;
}

.cp-img {
  width: 100%;
  filter: blur(6px);
}

.cp-name {
  font-size: 25px;
  margin-bottom: 5px;
}

.cp-box3 {
  display: flex;
  flex-direction: row;
  margin: 35px 0px 0px 15px;
  font-size: 12px;
  font-weight: 500;
  color: #9f9f9f;
}

.cp-choose {
  display: flex;
  flex-direction: row;
}

.cp-tips-box {
  margin: 10px 0px 0px 10px;
  display: flex;
  flex-wrap: wrap;
  width: 435px;
}

.cp-tips {
  margin-left: 10px;
  padding: 2px 8px;
  color: #f7de47;
  border: 2px solid #f7de47;
  border-radius: 5px;
  margin-bottom: 10px;
}

.cp-more {
  color: #747474;
  font-size: 14px;
  margin: 0px 25px 20px 25px;
}

.cp-btn-box {
  display: flex;
  flex-direction: row;
}

.cp-btn-hyh {
  border: 3px solid #747474;
  width: 40px;
  height: 40px;
  margin: 0px 20px 15px 25px;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  color: #747474;
  font-weight: 600;
}

.cp-btn-xd {
  border: 3px solid #747474;
  width: 200px;
  height: 41px;
  margin: 0px 25px 25px 25px;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.choose-box {
  display: flex;
  flex-direction: column;
  margin: 15px 0px 0px 20px;
}

.choose-btn {
  width: 41px;
  height: 41px;
  border-radius: 50%;
  border: 3px solid #747474;
  margin-bottom: 10px;
}

.choose-no {
  width: 16px;
  margin: 13px 13px;
}

.choose-btn-yes {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 3px solid #747474;
  box-shadow: 0 3px 0px 0px #747474;
  margin-bottom: 10px;
  background-color: #f7de47;
}

.no {
  animation-name: no;
  animation-duration: 1s;
}

@keyframes no {
  0% {
  }

  10% {
    transform: translateX(10px);
  }

  20% {
    transform: translateX(-10px);
  }
  30% {
    transform: translateX(10px);
  }
  40% {
    transform: translateX(-10px);
  }
  50% {
    transform: translateX(10px);
  }
  75% {
    transform: translateX(-10px);
  }
  to {
  }
}

.yes {
  animation-name: yes;
  animation-duration: 2s;
}

@keyframes yes {
  0% {
  }
  5% {
    transform: translateY(-10px);
  }
  10% {
    transform: translateY(10px);
  }
  15% {
    transform: translateY(-10px);
  }
  20% {
    transform: translateY(10px);
  }
  25% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(10px);
  }
  75% {
    transform: translateY(-10px);
  }
  to {
  }
}
</style>
