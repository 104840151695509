<template>
  <div class="container">
    <div class="pp-text">心动我的人</div>
    <div class="cp-card" v-for="cpinfo in cpList" :key="cpinfo.userinfo._id">
      <div class="cp-box1">
        <div class="img-box">
          <img class="cp-img" :src="cpinfo.userinfo.imgUrl" />
        </div>
      </div>
      <div class="cp-box2">
        <div class="cp-school">
          {{ cpinfo.userinfo.school }}·{{ cpinfo.userinfo.grade }}·{{
            cpinfo.userinfo.height
          }}
        </div>
      </div>
      <div class="cp-more">{{ cpinfo.userinfo.more }}</div>
      <div class="cp-btn-box">
        <div class="cp-btn-xd2" @click="mpYes(cpinfo._id)">心动一下</div>
      </div>
      <div style="height: 30px"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["openid"],
  data() {
    return {
      cpList: [],
    };
  },
  async created() {
    const mpOpenid = this.openid;
    console.log(mpOpenid);
    const tcb = this.$cloudbase;
    const db = tcb.database();
    const _ = db.command;
    const listRes = await db
      .collection("user")
      .where({
        userinfo: {
          mpOpenid,
        },
      })
      .get();
    console.log(listRes.data[0].likeMe);
    this.userId = listRes.data[0]._id;
    const likeList = listRes.data[0].likeMe;

    const cpRes = await db
      .collection("user")
      .where({
        _id: _.in(likeList),
        inLove: false,
        isShow: true,
      })
      .get();
    console.log(cpRes.data);
    this.cpList = cpRes.data;
  },
  methods: {
    async mpYes(cpId) {
      const userId = this.userId;
      console.log(cpId, 11, userId, 22);
      const tcb = this.$cloudbase;
      const mpYesRes = await tcb.callFunction({
        name: "firstYes",
        data: {
          cpId,
          userId,
        },
      });
      console.log(mpYesRes.result);
      // if (mpYesRes.result == "inlove") {
      //   this.$router.replace("Inquire");
      // }
    },
  },
};
</script>

<style>
.title {
  font-size: 24px;
  font-weight: 600;
  margin: 10px 25px 0 25px;
  color: #747474;
}
.cp-btn-xd2 {
  border: 3px solid #747474;
  width: 240px;
  height: 41px;
  margin: 0px 0px 25px 0px;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}
</style>
