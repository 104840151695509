<template>
  <div class="container">
    <div v-if="pay == false">
      <div v-if="pay == false" class="bg-img">
        <img
          class="logo"
          :class="{ loading: loading }"
          src="../assets/logo.png"
          alt
        />
      </div>
      <div v-if="loading == false">
        <div class="card">
          <!-- <input type="file" accept="image/*" /> -->
          <div v-if="isImgSrc == false" class="text">“上传一张照片吧”</div>
          <div v-else class="text">“照片随时可以换”</div>
          <img
            v-if="isImgSrc == true"
            @click="chooseImg()"
            class="user-img"
            :src="imgSrc"
            alt
          />
          <img
            v-else
            class="img-sum"
            @click="chooseImg()"
            src="../assets/sum.png"
            alt
          />
        </div>
        <div class="card">
          <div class="text">“你是男生还是女生”</div>
          <div
            class="a-sex0"
            :class="{ chooseBtn: girl }"
            @click="chooseGirl()"
          >
            我是可爱的女孩子
          </div>
          <div class="a-sex" :class="{ chooseBtn: boy }" @click="chooseBoy()">
            男生
          </div>
        </div>
        <div class="card">
          <div class="text">“你是哪所学校的”</div>
          <input v-model="school" class="a-input" type="text" />
        </div>
        <div class="card">
          <div class="text">“你现在大几？”</div>
          <div class="grade-box">
            <div
              class="a-grade"
              :class="{ chooseBtn: d1 }"
              @click="onGrade('大一')"
            >
              大一
            </div>
            <div
              class="a-grade"
              :class="{ chooseBtn: d2 }"
              @click="onGrade('大二')"
            >
              大二
            </div>
            <div
              class="a-grade"
              :class="{ chooseBtn: d3 }"
              @click="onGrade('大三')"
            >
              大三
            </div>
            <div
              class="a-grade"
              :class="{ chooseBtn: d4 }"
              @click="onGrade('大四')"
            >
              大四
            </div>
            <div
              class="a-grade"
              :class="{ chooseBtn: yjs }"
              @click="onGrade('研究生')"
            >
              研究生
            </div>
            <div
              style="margin-bottom: 35px"
              class="a-grade"
              :class="{ chooseBtn: byl }"
              @click="onGrade('毕业了')"
            >
              毕业了
            </div>
          </div>
        </div>
        <div class="card" v-if="gradeNum == 5">
          <div class="text">“你现在研几？”</div>
          <div class="grade-box">
            <div
              class="a-grade"
              :class="{ chooseBtn: y1 }"
              @click="onYjs('研一')"
            >
              研一
            </div>
            <div
              class="a-grade"
              :class="{ chooseBtn: y2 }"
              @click="onYjs('研二')"
            >
              研二
            </div>
            <div
              style="margin-bottom: 35px"
              class="a-grade"
              :class="{ chooseBtn: y3 }"
              @click="onYjs('研三')"
            >
              研三
            </div>
          </div>
        </div>
        <div class="card" v-if="gradeNum == 6">
          <div class="text">“你在哪座城市？”</div>
          <input v-model="city" class="a-input" type="text" />
        </div>
        <div class="card">
          <div class="text">“你身高多少？”</div>
          <input v-model="height" class="a-input" type="text" />
        </div>
        <div class="card">
          <div class="text">“留个微信吧”</div>
          <input v-model="wechat" class="a-input" type="text" />
        </div>
        <div class="cp-ps-text" style="margin-bottom: 10px">
          请勿填写原始微信号，报名后打开微信设置"允许搜索微信号添加"
        </div>
        <div class="card">
          <div class="text">“留个手机号吧”</div>
          <input v-model="phone" class="a-input" type="text" />
        </div>
        <div class="card">
          <div class="more-text">
            “介绍一下自己吧，可以谈谈自己的理想型，我们会人工为你匹配最合适的人”
          </div>
          <textarea rows="8" v-model="more" class="a-textarea" type="text" />
        </div>
        <!-- <div class="btn-box">
          <wx-open-subscribe
            :template="template"
            id="'subscribe-btn"
            @success="successSubscribe"
            @error="subErrorSubscribe"
          >
            <script type="text/wxtag-template">
              <style>
              .subscribe-btn{
                  border: 3px solid #747474;
                  width: 304px;
                  height: 44px;
                  margin: auto;
                  border-radius: 15px;
                  text-align: center;
                  line-height: 40px;
                  background-color: #f7de47;
                  color: #747474;
                  font-weight: 900;
                  font-size: 17px;
              }
                    </style>
                    <button class="subscribe-btn">报名</button>
            </script>
          </wx-open-subscribe>
        </div> -->
        <div class="btn-box">
          <div class="btn" @click="onApply">报名</div>
        </div>
      </div>
    </div>
    <div v-else>报名成功</div>
  </div>
</template>

<script>
import wx from "weixin-js-sdk";
export default {
  name: "Apply",
  props: ["openid", "ticketName"],
  data() {
    return {
      loading: false,
      isImgSrc: false,
      imgSrc: "",
      url: "",
      pay: false,
      payText: "未支付",
      sex: "",
      girl: false,
      boy: false,
      radio2: "1",
      d1: false,
      d2: false,
      d3: false,
      d4: false,
      yjs: false,
      byl: false,
      y1: false,
      y2: false,
      y3: false,
      grade: "",
      gradeNum: "",
      city: "",
      school: "",
      wechat: "",
      phone: "",
      height: "",
      more: "",
      isRe: false,
      template: [
        "MonI8Qtl3tmhIizxDsrrplBQiwbVrFSjs0YXA-26Urg",
        "czHtiOm545II5qUbp9wa2Z1JkqsjjlkdyChMQCYwrDM",
        "Z88AjSE1V_cBP5iFyx9RlxTozB0w9fmv28kKohFiWgY",
        "eBHdlRgJOGphIerjExqBABwB9GXQOLB5TI5G1pic8RU",
        "vT3TjyQR8qxJl91j_cMM4yyOIbhOQI265UhwZR-k3-s",
      ],
    };
  },
  methods: {
    //获得签名
    async onQm() {
      const noncestr = Math.random().toString(36).substr(2, 15);
      const timestamp = parseInt(new Date().getTime() / 1000);
      const tcb = this.$cloudbase;
      const ticketRes = await tcb.callFunction({
        // 云函数名称
        name: "getSignature",
        // 传给云函数的参数
        data: {
          url: this.url,
          timestamp,
          noncestr,
        },
      });
      console.log(ticketRes.result);
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: "wx7fb8dcd86d54fbca", // 必填，公众号的唯一标识
        timestamp, // 必填，生成签名的时间戳
        nonceStr: noncestr, // 必填，生成签名的随机串
        signature: ticketRes.result, // 必填，签名
        jsApiList: [
          "chooseWXPay",
          "chooseImage",
          "getLocalImgData",
          "uploadImage",
          "downloadImage",
        ], // 必填，需要使用的JS接口列表
        openTagList: ["wx-open-subscribe"],
      });
    },

    //是否登录
    async isSignin() {
      const tcb = this.$cloudbase;
      const db = tcb.database();
      const isSigninRes = await db
        .collection("user")
        .where({
          userinfo: {
            mpOpenid: this.openid,
          },
        })
        .get();
      console.log(isSigninRes);

      if (isSigninRes.data.length > 0) {
        const userinfo = isSigninRes.data[0].userinfo;
        this.city = userinfo.city;
        this.grade = userinfo.grade;
        this.gradeNum = userinfo.gradeNum;
        this.height = userinfo.height;
        this.imgSrc = userinfo.imgUrl;
        this.more = userinfo.more;
        this.school = userinfo.school;
        this.sex = userinfo.sex;
        this.wechat = userinfo.wechatId;
        this.phone = userinfo.phone;
        this.isImgSrc = true;
        this.isRe = false;
        const grade = userinfo.grade;
        const sex = userinfo.sex;

        if (grade == "研一" || grade == "研二" || grade == "研三") {
          this.onGrade("研究生");
          this.onYjs(grade);
        } else {
          this.onGrade(grade);
        }
        if (sex == "boy") {
          this.chooseBoy();
        } else {
          this.chooseGirl();
        }
      } else {
        //1
      }
    },

    //注册信息
    async mpSignin() {
      const tcb = this.$cloudbase;
      console.log(this.school);
      console.log(this.grade);
      console.log(this.gradeNum);
      console.log(parseInt(this.height));
      const height = parseInt(this.height);
      console.log(this.more);
      console.log(this.sex);
      console.log(this.wechat);
      console.log(this.ticketName);

      if (!this.isImgSrc) {
        alert("照片还没上传");
        this.loading = false;
      } else if (this.sex.length == 0) {
        alert("性别还没填写");
        this.loading = false;
      } else if (this.school.length == 0) {
        alert("学校还没填写");
        this.loading = false;
      } else if (this.gradeNum.length == 0) {
        alert("年级还没填写");
        this.loading = false;
      } else if (this.height.length == 0) {
        alert("身高还没填写");
        this.loading = false;
      } else if (this.wechat.length == 0) {
        alert("微信号还没填写");
        this.loading = false;
      } else if (this.phone.length == 0) {
        alert("手机号还没填写");
        this.loading = false;
      } else if (this.isRe) {
        //看看有没有重新上传图片
        let imgName = this.randomString() + ".jpeg";
        var file = this.dataURLtoFile(this.imgSrc, imgName);
        const imgRes = await tcb.uploadFile({
          // 云存储的路径
          cloudPath: "webImg/" + imgName,
          // 需要上传的文件，File 类型
          filePath: file,
        });

        console.log(imgRes);

        var couldId = imgRes.fileID;

        const imgUrlRes = await tcb.getTempFileURL({
          fileList: [couldId],
        });
        console.log(imgUrlRes);

        var imgUrl = imgUrlRes.fileList[0].tempFileURL;
        console.log(imgUrl);

        const mpPayRes = await tcb.callFunction({
          name: "mpSignin",
          data: {
            openid: this.openid,
            sex: this.sex,
            school: this.school,
            grade: this.grade,
            gradeNum: this.gradeNum,
            height,
            wechat: this.wechat,
            phone: this.phone,
            more: this.more,
            city: this.city,
            imgUrl,
            ticketName: this.ticketName,
          },
        });
        console.log(mpPayRes);
        this.loading = false;
        this.$router.push("Self");
      } else {
        var imgUrl1 = this.imgSrc;
        const mpPayRes = await tcb.callFunction({
          name: "mpSignin",
          data: {
            openid: this.openid,
            sex: this.sex,
            school: this.school,
            grade: this.grade,
            gradeNum: this.gradeNum,
            height,
            wechat: this.wechat,
            phone: this.phone,
            more: this.more,
            city: this.city,
            imgUrl: imgUrl1,
            ticketName: this.ticketName,
          },
        });
        console.log(mpPayRes);
        this.loading = false;
        this.$router.push("Self");
      }
    },
    chooseImg() {
      console.log(this.openid);
      var that = this;
      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ["original", "compressed"], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ["album", "camera"], // 可以指定来源是相册还是相机，默认二者都有
        success: function (req) {
          //   var localIds = res.localIds; // 返回选定照片的本地ID列表，localId可以作为img标签的src属性显示图片
          wx.getLocalImgData({
            localId: req.localIds[0].toString(),
            success: function (res) {
              console.log(res);

              const localData = res.localData;
              let imageBase64 = "";
              if (localData.indexOf("data:image") == 0) {
                //苹果的直接赋值，默认生成'data:image/jpeg;base64,'的头部拼接
                imageBase64 = localData;
              } else {
                //此处是安卓中的唯一得坑！在拼接前需要对localData进行换行符的全局替换
                //此时一个正常的base64图片路径就完美生成赋值到img的src中了
                imageBase64 =
                  "data:image/jpeg;base64," + localData.replace(/\n/g, "");
              }
              console.log(imageBase64);
              that.imgSrc = imageBase64;
              that.isImgSrc = true;
              that.isRe = true;
            },
          });
        },
      });
    },
    //将base64转换为文件
    dataURLtoFile: function (dataurl, filename) {
      var arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },

    randomString: function (e) {
      e = e || 32;
      var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
        a = t.length,
        n = "";
      for (let i = 0; i < e; i++) n += t.charAt(Math.floor(Math.random() * a));
      return n;
    },
    chooseGirl() {
      this.sex = "girl";
      this.girl = true;
      this.boy = false;
      console.log(this);
    },

    chooseBoy() {
      this.sex = "boy";
      this.girl = false;
      this.boy = true;
      console.log(this);
    },
    onGrade(e) {
      switch (e) {
        case "大一":
          this.d1 = true;
          this.d2 = false;
          this.d3 = false;
          this.d4 = false;
          this.d5 = false;
          this.d6 = false;
          this.grade = "大一";
          this.gradeNum = 1;
          break;
        case "大二":
          this.d1 = false;
          this.d2 = true;
          this.d3 = false;
          this.d4 = false;
          this.yjs = false;
          this.byl = false;
          this.grade = "大二";
          this.gradeNum = 2;
          break;
        case "大三":
          this.d1 = false;
          this.d2 = false;
          this.d3 = true;
          this.d4 = false;
          this.yjs = false;
          this.byl = false;
          this.grade = "大三";
          this.gradeNum = 3;
          break;
        case "大四":
          this.d1 = false;
          this.d2 = false;
          this.d3 = false;
          this.d4 = true;
          this.yjs = false;
          this.byl = false;
          this.grade = "大四";
          this.gradeNum = 4;
          break;
        case "研究生":
          this.d1 = false;
          this.d2 = false;
          this.d3 = false;
          this.d4 = false;
          this.yjs = true;
          this.byl = false;
          this.grade = "研究生";
          this.gradeNum = 5;
          break;
        case "毕业了":
          this.d1 = false;
          this.d2 = false;
          this.d3 = false;
          this.d4 = false;
          this.yjs = false;
          this.byl = true;
          this.grade = "毕业了";
          this.gradeNum = 6;
          break;
      }
    },
    onYjs(e) {
      console.log(this.grade);
      switch (e) {
        case "研一":
          this.y1 = true;
          this.y2 = false;
          this.y3 = false;
          this.grade = "研一";
          break;
        case "研二":
          this.y1 = false;
          this.y2 = true;
          this.y3 = false;
          this.grade = "研二";
          break;
        case "研三":
          this.y1 = false;
          this.y2 = false;
          this.y3 = true;
          this.grade = "研三";
          break;
      }
    },
    onApply() {
      this.loading = true;
      this.mpSignin();
    },
    successSubscribe(e) {
      let subscribeDetails = JSON.parse(e.detail.subscribeDetails);
      let status = JSON.parse(
        subscribeDetails[
          ("MonI8Qtl3tmhIizxDsrrplBQiwbVrFSjs0YXA-26Urg",
          "czHtiOm545II5qUbp9wa2Z1JkqsjjlkdyChMQCYwrDM",
          "Z88AjSE1V_cBP5iFyx9RlxTozB0w9fmv28kKohFiWgY",
          "eBHdlRgJOGphIerjExqBABwB9GXQOLB5TI5G1pic8RU",
          "vT3TjyQR8qxJl91j_cMM4yyOIbhOQI265UhwZR-k3-s")
        ]
      ).status;
      if (status == "accept") {
        //同意订阅该模板
        console.log("success");
        this.onApply();
      } else if (status == "reject") {
        //拒绝订阅该模板
        console.log("fail");
      }
    },
    subErrorSubscribe(e) {
      let errMsg = e.detail.errMsg; //错误提示
      let errCode = e.detail.errCode; //错误码
      console.log(errMsg, errCode);
    },
  },
  created() {
    const url = location.href.split("#")[0];
    this.url = url;
    //完成签名
    this.onQm();
    this.isSignin();
  },
};
</script>

<style>
.container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.bg-img {
  display: flex;
  margin: auto;
  margin-top: 40px;
}

.logo {
  width: 282px;
  height: 282px;
  margin: auto;
}

.user-img {
  width: 280px;
  height: 100%;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 30px;
  border-radius: 3px;
}

.input-input {
  border: 3px solid #747474;
  width: 300px;
  height: 41px;
  margin: auto;
  margin-top: 40px;
  margin-bottom: 35px;
  border-radius: 15px;
  text-align: center;
  font-weight: 900;
  color: #747474;
  font-size: 17px;
}

.btn {
  border: 3px solid #747474;
  width: 304px;
  height: 44px;
  margin: auto;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.card {
  display: flex;
  flex-direction: column;
  width: 335px;
  margin: auto;
  margin-top: 40px;
  background-color: #ffffff;
  box-shadow: #c1c1c144 0 6px 6px 6px;
  border-radius: 20px;
  color: #747474;
  font-size: 21px;
  font-weight: 500;
}

.text {
  margin: auto;
  margin-top: 30px;
}

.img-sum {
  width: 80px;
  margin: auto;
  margin-top: 35px;
  margin-bottom: 55px;
}

.a-sex {
  border: 3px solid #747474;
  width: 280px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
  border-radius: 20px;
  text-align: center;
  line-height: 60px;
}

.a-sex0 {
  border: 3px solid #747474;
  width: 280px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  border-radius: 20px;
  text-align: center;
  line-height: 60px;
}

.chooseBtn {
  background-color: #f7de47;
  color: #747474;
}

.a-input {
  border: 3px solid #747474;
  width: 280px;
  height: 60px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
  border-radius: 20px;
  text-align: center;
  color: #747474;
  font-size: 21px !important;
  font-weight: 500;
}

.grade-box {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.a-grade {
  border: 3px solid #747474;
  width: 125px;
  height: 40px;
  margin: auto;
  margin-top: 10px;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
}

.more-text {
  width: 270px;
  margin: auto;
  margin-top: 30px;
}

.btn {
  border: 3px solid #747474;
  width: 300px;
  height: 43px;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
  border-radius: 15px;
  text-align: center;
  line-height: 40px;
  background-color: #f7de47;
  color: #747474;
  font-weight: 900;
}

.a-textarea {
  border: 3px solid #747474;
  width: 250px;
  padding: 15px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 35px;
  border-radius: 20px;
  height: 150px;
  font-size: 17px !important;
}

.loading {
  animation-name: loading;
  animation-duration: 5s;
}

@keyframes loading {
  0% {
    transform: rotateY(0deg);
  }
  25% {
    transform: rotateY(180deg);
  }
  50% {
    transform: rotateY(360deg);
  }
  75% {
    transform: rotateY(180deg);
  }
  to {
    transform: rotateY(360deg);
  }
}

.btn-box {
  margin: auto;
  width: 310px;
  margin-top: 20px;
  margin-bottom: 40px;
}
</style>
