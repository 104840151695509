<template>
  <div id="app">
    <router-view
      :openid="openid"
      :userRes="userRes"
      :cpInfo="cpInfo"
      :loveInfo="loveInfo"
    ></router-view>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      user: false,
      userRes: {},
      cpInfo: {},
      loveInfo: {},
    };
  },
  async created() {
    await this.login();
    await this.isUser();
  },

  methods: {
    //登录获取openid
    async login() {
      const tcb = this.$cloudbase;
      const auth = await tcb.auth({
        persistence: "session",
      });
      const provider = await auth.weixinAuthProvider({
        appid: "wx7fb8dcd86d54fbca",
        scope: "snsapi_base",
      });
      console.log(provider, "provider");
      const isLogin = await auth.getLoginState();
      if (isLogin) {
        let loginState = await provider.getRedirectResult();
        this.openid = loginState.user.openid;
      } else {
        // 此时未登录，执行您的登录流程
        console.log("还没登陆");
        await provider.signInWithRedirect();
        let loginState = await provider.getRedirectResult();
        console.log(loginState, "loginState");
        this.openid = loginState.user.openid;
      }
    },

    //看看用户是否填过资料
    async isUser() {
      const tcb = this.$cloudbase;
      const db = tcb.database();
      const isUserRes = await db
        .collection("user")
        .where({
          userinfo: {
            mpOpenid: this.openid,
          },
        })
        .get();
      console.log("isUserRes", isUserRes);
      if (isUserRes.data.length > 0) {
        this.userRes = isUserRes.data[0];
        if (isUserRes.data[0].inLove == false) {
          this.user = true;
          this.$router.replace("Self");
        }
        //确定有用户资料时再开启监听
        await this.inlove();
      } else {
        this.$router.replace("Apply");
      }
    },
    async inlove() {
      const tcb = this.$cloudbase;
      const db = tcb.database();
      const sex = this.userRes.userinfo.sex;
      if (sex == "boy") {
        let watcher = db
          .collection("inlove")
          .where({
            boyId: this.userRes._id,
            break: false,
          })
          .watch({
            onChange: (snapshot) => {
              console.log("snapshot", snapshot);
              if (
                snapshot.docChanges.length > 0 &&
                snapshot.docChanges[0].doc.break == false
              ) {
                this.loveInfo = snapshot.docChanges[0].doc;
                this.cpInfo = snapshot.docChanges[0].doc.girlInfo;
                this.$router.replace("Inquire");
              }
              if (
                snapshot.docChanges.length > 0 &&
                snapshot.docChanges[0].doc.break == true
              ) {
                this.$router.replace("Self");
              }
            },
            onError: function (err) {
              console.error("the watch closed because of error", err);
            },
          });
        console.log("watcher", watcher);
      } else {
        let watcher = db
          .collection("inlove")
          .where({
            girlId: this.userRes._id,
            break: false,
          })
          .watch({
            onChange: (snapshot) => {
              console.log("snapshot", snapshot);
              if (
                snapshot.docChanges.length > 0 &&
                snapshot.docChanges[0].doc.break == false
              ) {
                this.loveInfo = snapshot.docChanges[0].doc;
                this.cpInfo = snapshot.docChanges[0].doc.boyInfo;
                this.$router.replace("Inquire");
              }
              if (
                snapshot.docChanges.length > 0 &&
                snapshot.docChanges[0].doc.break == true
              ) {
                this.$router.replace("Self");
              }
            },
            onError: function (err) {
              console.error("the watch closed because of error", err);
            },
          });
        console.log("watcher", watcher);
      }
    },
  },
};
</script>

<style>
body {
  margin: 0;
  padding: 0;
  background-color: #f0f0f0;
  color: #747474;
}
</style>
